<template>   
    <b-card no-body>
        <b-card-body>                  
            <Filters
                :filtroDataCliente="filtroDataCliente"
                
                :tipoFechaSelect="tipoFechaSelect"
                :tipoDataClienteSelect="tipoDataClienteSelect"                
                :filter="filter"
                :dateYYMM="dateYYMM"
                :minYearMax="minYearMax"
                :dateCurrentInit="dateCurrentInit"
                :dateCurrentEnd="dateCurrentEnd"
                :perPage="perPage"
                :pageOptions="pageOptions"
                @get-Orders-by-Date-Range-filter="getOrdersByDateRangeFilter"
                @change-per-page="changePerPage"
                @filtrar-resultado-tabla="filtrarResultadoTabla"
                @clear-filtrar-resultado-tabla="clearFiltrarResultadoTabla"
                 @clear-filter="resetFilter"
                 @excel-data="excelData"
            />

            <div class="text-center" v-if="isloadingProcess">
                <b-spinner label="Loading..." variant="success"/>                          
            </div> 

            <Roomlist v-else
                :filteredOrders="filteredOrders"
                :fields="fieldsRooms"
                :cancelMotive="cancelMotive"
                :currentPage="currentPage"
                :perPage="perPage"
                :filter="filter"
                :filterOn="filterOn"
                :sortBy="sortBy"
                :sortDesc="sortDesc"
                :sortDirection="sortDirection"
                :onFiltered="onFiltered"
                @refresh="refresh"
            />
 
        </b-card-body>                
    </b-card>
</template>

<script>
//eslint-disable-line
import * as moment from 'moment'
import { mapActions, mapState, mapGetters } from 'vuex'  
import { fieldsRooms, filtroDataCliente } from '@/data/data'   
import { currentDate, yearsMinMax } from '@/helpers/helpers' 

import Filters from '@/modules/groups/components/Filters'
import Roomlist from '@/modules/groups/components/Roomlist'

export default {
    async created() {
        await this.fetchRooms(1)
        let idCanal =this.user.canalventa 
        this.cancelMotive= await this.fetchCancelMotive(idCanal)
       
    },
    components: {
        //SFC
        Filters,
        Roomlist      
    },
    data() {
        return {
            tipoFechaSelect: 0,  
            tipoDataClienteSelect: 'Elija una opción',                            
            fieldsRooms,
            filtroDataCliente,
            idUserSelect: null,
            filter: null,
            perPage: 15,
            dateYYMM: moment().format("YYYY-MM"),
            dateCurrentInit: '',
            dateCurrentEnd: '',
            hotel:0,
            fechaActual: currentDate(), 
            minYearMax: yearsMinMax(),
            pageOptions: [5, 10, 15, { value: 100, text: "Todos" }],
            totalRows: 1,
            currentPage: 1,
            filterOn: [],  
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            cancelMotive:[]
        }
    },
    computed:{
        ...mapState('auth',['user']),
        ...mapState('groups',['isloadingProcess','rooms','setRoomsDetail']),
        ...mapGetters('groups',['filteredOrders']),
    },
    methods: {
      ...mapActions('groups',['fetchRooms','fetchTypeGroups','fetchRoomsDetail','fetchRoomsExcel','fetchRoomsUpdate','fetchCancelMotive']),
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
      },
        async getOrdersByDateRangeFilter(data){
            const { fini, ffin, typegroup,hotel } = data            
            this.tipoFechaSelect = typegroup,                           
            this.hotel=hotel
            this.dateCurrentInit = fini
            this.dateCurrentEnd = ffin 
            document.getElementById('dateStart').value = fini
            document.getElementById('dateEnd').value = ffin   
                
           await this.fetchRooms(data)
        },
        async resetFilter(){
             await this.fetchRooms(1) 
        },
        async excelData(data){
                   
           data.fileName =  'RoomList-'+this.fechaActual+'-'+this.user.name.split(' ').join('-')+'-'+this.user.lastname.split(' ').join('-')+'.xls',     
         
          
            await this.fetchRoomsExcel(data)
        },
      
        changePerPage( data ){            
            this.perPage = data            
        },
        filtrarResultadoTabla( data ){
            this.filter = data
        },
        clearFiltrarResultadoTabla(){
            this.filter = ''
        },
        async refresh(){
           const payload={ fini:this.dateCurrentEnd, ffin:this.dateCurrentInit, typegroup:this.tipoFechaSelect,hotel:this.hotel }
          
            await this.fetchRoomsUpdate(payload)
        }
        
    },
}
</script>