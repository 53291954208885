<template>
  <b-modal
    size="sm"
    :id="'modal-locked' + room.id"
    :title="'Desbloquear  habitacion '+room.roomtypename"
    ok-only
    hide-footer
  >

    <b-card-text>
      <b-row class="mb-2">
        <b-col md="6">
          <div class="text-right">
            <b-button variant="primary" @click="confirm(room)">
              <b-spinner small v-if="isSaving" />No
            </b-button>
          </div>
        </b-col>
        <b-col md="6">
          <div class="text-left">
            <b-button variant="primary" @click="cancelRoom(room)">
              <b-spinner small v-if="isSaving" />Si
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-text>
  </b-modal>
</template>

<script>
import * as moment from 'moment'
import { mapActions, mapMutations } from "vuex"
import { currentDate } from "@/helpers/helpers"
import { adultOptions,childrenOptions, ageChildrenOptions, searchBookingData, namesPrefix, } from "@/data/data"
export default {
  async mounted() {

           this.invalid=false
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
     num: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
    
    };
  },
  computed: {
 

  },
  methods: {
        ...mapActions("groups", [ "sendUnlockGroup","fetchRoomBooking"]),
  
    ...mapMutations("groups", [
      "addArrayNinios",
      "setAgeChild",
      "removeAgeChild",
      "setTotalNights"
    ]),
   async confirm(room) {
    
     this.$root.$emit('bv::hide::modal', 'modal-locked' + room.id)
    },
    async cancelRoom(room) {
      
      // console.log( payload)
     this.isSaving = true;
     room.bookingid=room.id
     const payload = { orderbooking:room };
     await this.sendUnlockGroup(payload);
     
     this.isSaving=false

     this.$root.$emit('bv::hide::modal', 'modal-locked' + room.id)


       const payloadUpdate = {
        idGroup: room.group,
        idRoom: room.room,
        datein: room.datein,
        roomOnly:"",
        dateout: room.dateout,
      };

      
      await this.fetchRoomBooking(payloadUpdate);
    },
  },
};
</script>


