<template>
  <div>
    <b-table
      show-empty
      empty-text="No hay registros para mostrar, haga una busqueda"
      small
      responsive
      stacked="md"
      :items="filteredOrders"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
      class="mt-2"
    >
      <template #cell(actions)="row">
        <a
          class="pdfInfo"
          :href="attrLink + '/' + row.item.id + '/'"
          download
          target="_blank"
        >
          <b-button size="sm" variant="success" class="mr-1">
            <span class="pdfIconLoad"> 📄 </span>
          </b-button>
        </a>
        <b-button size="sm" @click="row.toggleDetails" class="mr-2">
          {{ row.detailsShowing ? "Ocultar ➖" : "Mostrar ➕" }}
        </b-button>
      </template>

      <template #row-details="row">
        <b-card>
          <b-row
            v-for="roomsList in row.item.rooms.data"
            :key="roomsList.id"
            :value="roomsList.id"
            :class="roomsList.separator"
          >
            <b-col class="title"
              ><b>Categoria</b>
              <div>{{ roomsList.roomCode }}-{{ roomsList.roomName }}</div>
            </b-col>
            <b-col class="title cursorPointer"  @click="showModalEditAllotment( row.item,roomsList)"
            
              ><b>Disponible </b><a class="text-decoration-underline">{{ roomsList.available }}</a>
            </b-col>
            <b-col class="title"
              ><b>Llegada </b> {{ roomsList.arrivaldate }}
            </b-col>
            <b-col class="title"
              ><b>Salida</b> {{ roomsList.departuredate }}</b-col
            >
            <b-col class="title"
              ><b>Noches</b>{{ roomsList.nightsRooms }}
            </b-col>
            <b-col class="title"><b>Adultos</b> {{ roomsList.adults }} </b-col>
            <b-col class="title"><b>Niños</b> {{ roomsList.children }} </b-col>
            <b-col class="title"><b>Tarifa</b>$ {{ roomsList.rate }} </b-col>
            <b-col class="title"><b>Total</b>$ {{ roomsList.total }} </b-col>
            <b-col class="title noborde"
              ><b-button
                v-b-toggle="
                  'my-collapse' + roomsList.roomId + roomsList.arrivaldate+roomsList.departuredate+roomsList.nightsRooms
                "
                @click.prevent="
                  setbookingInfo(
                    row.item.id,
                    roomsList.roomId,
                    roomsList.open,
                    roomsList,
                    row.item.grouphotel
                  )
                "
                >Detalle</b-button
              >
            </b-col>
            <ModalAllotment
                      :room="roomsList"
                      @refresh="refresh"
                      @send-allotment="sendAllotment"
                    />
            <b-collapse
              class="mb-2"
              :id="'my-collapse' + roomsList.roomId + roomsList.arrivaldate+roomsList.departuredate+roomsList.nightsRooms"
            >
              <table v-if="roomsList.book.length!=0" class="table table-bordered table-sm ">
                <thead>
                  <tr>
                    <th class="title"><b>N° POS</b></th>
                    <th class="title"><b>Cuarto</b></th>
                    <th class="title"><b>Nombre</b></th>
                    <th class="title"><b>Llegada</b></th>
                    <th class="title"><b>Salida</b></th>
                    <th class="title"><b>Adultos</b></th>
                    <th class="title"><b>Niños</b></th>
                    <th class="title"><b>Tarifa</b></th>
                    <th class="title"><b>Total</b></th>
                    <th class="title"><b>Total Pagado</b></th>
                    <th class="title"><b>Estatus</b></th>
                    <th class="title"><b>N° reserva </b></th>
                    <th class="title"><b>Acciones</b></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="dataList in roomsList.book"
                    :key="dataList.id"
                    :value="dataList.id"
                    :class=" dataList.guestReserved  ? bloquedo : desBloquedo  "
                  >
                    <td class="listItem">POS-{{ dataList.orderdetail.order_id }}-{{ dataList.orderdetail.orderdetail_id }}</td>

                    <td class="listItem">
                      {{ dataList.roomtypecode }} - {{ dataList.roomtypename }}
                    </td>

                    <td class="listItem">
                      {{ dataList.givenname }} {{ dataList.surname }}
                    </td>

                    <td class="listItem">{{ dataList.datein }}</td>
                    <td class="listItem">{{ dataList.dateout }}</td>
                    <td class="listItem">{{ dataList.adults }}</td>
                    <td class="listItem">{{ dataList.children }}</td>
                    <td class="listItem">${{ dataList.rateInit }}</td>
                    <td class="listItem">${{ dataList.total }}</td>
                     <td class="listItem">${{ dataList.orderdetail.totalpay }}</td>
                    <td class="listItem">{{ dataList.statusName }}</td>
                    <td class="listItem">{{ dataList.dingusbooking }}</td>
                    <td class="listItem">
                      <b-button
                       v-if="dataList.statusName!='Cancelled' "
                        size="sm"
                        v-b-tooltip.hover.v-primary
                        title="Editar"
                        class="mr-1"
                        variant="primary"
                        @click="showModal(dataList.id)"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-button>
               
                      <b-button
                      v-if="dataList.statusName!='Cancelled' "
                        size="sm"
                         v-b-tooltip.hover.v-primary
                        title="Cancelar"
                        class="mr-1"
                        variant="danger"
                        @click="showModalDelete(dataList.id)"
                      >
                        <b-icon icon="trash-fill"></b-icon>
                      </b-button>
                    </td>
                     <ModalReference
                      :room="roomInfoData.length > 0 ? roomInfoData[0]:dataList" 
                      :roomInfoData="dataList"
                      :roomCode="dataList.roomtypecode"
                      :contractInfo="row.item"
                      :roomsList="roomsList"
                      @refresh="refresh"
                    />
                    <ModalDelete
                      :room="dataList"
                      :num="roomsList.book.length"
                      :cancelMotive="cancelMotive"
                      @refresh="refresh"
                    />
                    <ModalLocked
                      :room="dataList"
                      :num="roomsList.book.length"
                      @refresh="refresh"
                    />
        
                    
                  </tr>
                </tbody>
              </table>
              <div  v-else-if="roomsList.book.length==0 && !roomsList.isloadvalid" >
              <b-alert  variant="danger"  show>
                <div class="alert-body text-center">
                    <span>
                    <strong>¡Sin registros!</strong>No se encontraron 
                    registrados.
                  </span>
                </div>
            </b-alert>
            </div>
              <div v-if="roomsList.isloadvalid" class="text-center collapse">
                <b-spinner label="Loading..." variant="success" />
              </div>
            </b-collapse>
          </b-row>
        </b-card>
      </template>
    </b-table>
     
  </div>
</template>
<script>
import ModalReference from "@/modules/groups/components/ModalReference";
import ModalDelete from "@/modules/groups/components/ModalDelete";
import ModalLocked from "@/modules/groups/components/ModalLocked";
import ModalAllotment from "@/modules/groups/components/ModalAllotment";
import { mapActions, mapState, mapMutations } from "vuex";
import { acl } from "@/modules/auth/mixins/acl"
import { showAlertMessage } from "@/helpers/helpers";
export default {
  mixins: [acl],
  props: {
    filteredOrders: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },

    currentPage: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    filter: {
      type: String,
      required: false,
    },
    filterOn: {
      type: Array,
      required: true,
    },
    sortBy: {
      type: String,
      required: true,
    },
    sortDesc: {
      type: Boolean,
      required: true,
    },
    sortDirection: {
      type: String,
      required: true,
    },
    onFiltered: {
      type: Function,
      required: true,
    },
    cancelMotive:{
      type: Array,
      required: true,
    },
  },
  components: {
    ModalReference,
    ModalDelete,
    ModalLocked,
    ModalAllotment
  },
  computed: {
    ...mapState("groups", [
      "roomBooking",
      "roomsTypes",
      "roomsTransactions",
      "listPayOrders",
    ]),
  },
  data() {
    return {
      bloquedo:"warning",
      desBloquedo:"",
      roomInfoData:[],
      isloadvalid: false,
      attrLink: process.env.VUE_APP_IMG_SRC_API + "/EventGroups/Contratos",
      itemAvailable:0
    };
  },
  methods: {
    ...mapActions("groups", [
      "fetchRoomBooking",
      "fetchRoomsTypes",
      "fetchTransactionsBooking",
      "fetchRoomBookingInfo",
      "fetchRoomsId",
      "sendAllomentRoom"
    ]),
    ...mapMutations("groups", ["setRoomsTransactions","setRoomBookingGroup", "setValidTransactions",]),

    async refresh() {
      
      this.$emit("refresh");
    },
    async showModal(id) {
         const payload = {
        idGroup: "",
        idRoom:"",
        idRoomOnly:id,
        datein: "",
        dateout:"",
      };
      this.roomInfoData= await this.fetchRoomBookingInfo(payload)
      this.$root.$emit("bv::show::modal", "modal-reference" + id);
    },
    showModalDelete(id) {
      this.$root.$emit("bv::show::modal", "modal-delete" + id);
    },
    showModalLocked (id) {
      this.$root.$emit("bv::show::modal", "modal-locked" + id);
    },
    async setbookingInfo(idGroup, idRoom, open, roomsList, hotel) {
      roomsList.isloadvalid = true;
      const payload = {
        idGroup: idGroup,
        idRoomOnly: "",
        idRoom: idRoom,
        datein: roomsList.arrivaldate,
        dateout: roomsList.departuredate,
      };
      if (open == false) {
        await this.fetchRoomsTypes(hotel);
        await this.fetchRoomBooking(payload);
      }
      roomsList.isloadvalid = false;
    },
    async  showModalEditAllotment (group,roomsList) {
      
      if(this.can('groups_menu_show_allotment_modal')){
  
    
      let groupSelect= await this.fetchRoomsId(group.id)
      
      this.setRoomBookingGroup(...groupSelect)
      this.$root.$emit("bv::show::modal", "modal-allotment" + roomsList.roomId);
      }
    },


    async sendAllotment(roomList){
     await this.sendAllomentRoom(roomList);
     let groupSelect= await this.fetchRoomsId(roomList.groupID) 
     this.setRoomBookingGroup(...groupSelect)
     this.$root.$emit('bv::hide::modal', 'modal-allotment'+ roomList.room.roomId)
     showAlertMessage('Ok: proceso finalizado', 'InfoIcon', 'El grupo ha sido actualizado exitosamente', 'success', 4000, 'bottom-right' )
    }
    
  },
};
</script>


<style lang="scss" scoped>
.row.separator {
  margin-top: 42px;
  /* border: 1px solid #000; */
}
.title.col b {
  display: block;
  /* text-align: center; */
  background: #f3f2f7;
}

.title.col {
  text-align: center;
  padding: 0;
  border: solid 1px;
  margin: 0px 0 11px 0;

  /* font-size: 13px; */
}
.listItem.col {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  text-align: center;
  border: 1px solid rgba(86, 61, 124, 0.2);
}
.titleTotal {
  width: 100%;
  text-align: center;
  padding: 19px 0 15px 0;
  border: 1px solid;
}
.titleTotal b {
  display: block;
  background: #f3f2f7;
  text-transform: uppercase;
  padding: 0px 15px 0px 15px;
}

.titleTotal span {
  display: inline-block;
  border: solid 1px;
  /* padding: 0 10px 0 10px; */
}
.collapse {
  display: block;
  width: 100%;
  /*margin: 22px 0 51px 0;
  padding: 0px 40px 0 13px;*/
}
button.btn.mr-2.btn-secondary.btn-sm {
  font-size: 10px;
  background-color: #22292f !important;
}
.card-body .collapsed,
.card-body .not-collapsed {
  padding: 10px;
  margin: 2px 0 0 0px;
}
a.pdfInfo {
  display: block;
  margin: 3px auto;
  text-align: center;
}
button.btn.mr-1.btn-primary.btn-sm {
  display: inline-block;
  /* width: 20px; */
  margin: 0 0 0 4px !important;
  padding: 5px;
}
button.btn.mr-1.btn-danger.btn-sm {
  display: inline-block;
  /* width: 20px; */
  margin: 0 0 0 8px !important;
  padding: 5px;
}

.table-bordered {
  font-size: 12px;
}
.noborde {
  border: 0 !important;
}

.warning{
color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}
.cursorPointer{
  cursor: pointer;
}
a.text-decoration-underline {
    text-decoration: underline;
}
</style>
