<template>
  <div>
    <b-row>
      <b-col md="2">
        <strong>Por Hotel</strong>
        <select class="custom-select" v-model="hotel">
          <option value="0">Seleccione hotel</option>
          <option v-for="hotel in hotels" :value="hotel.id" :key="hotel.id">
            {{ hotel.name }}
          </option>
        </select>
      </b-col>
      <b-col md="2">
        <label>Elija tipo de Grupo</label>
        <b-form-group>
          <b-form-select v-model="typegroup">
            <option value="0">Seleccione tipo de Grupo</option>
            <option v-for="dc in typeGroups" :key="dc.id" :value="dc.id">
              {{ dc.tipogruponame }}
            </option>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col md="2">
        <label for="">Fecha llegada</label>
        <b-form-group>
          <b-form-input id="dateStart" type="date" :value="fechaInicio">
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="2">
        <label for="">Fecha Salida</label>
        <b-form-group>
          <b-form-input type="date" id="dateEnd" :value="fechaFin">
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <label>Búsqueda</label>
        <b-input-group>
          <b-form-input
            v-model="filtroText"
            type="search"
            placeholder="Buscar"
            @input="filtrarInfo"
          ></b-form-input>
          <b-input-group-append>
            <b-button
              :disabled="!filtroText"
              @click="clearFiltroText"
              variant="danger"
              class="btn-sm"
            >
              <b-icon-trash-fill></b-icon-trash-fill>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="mb-1 mt-2">
      <b-col>
        <b-button variant="danger" class="mr-1" @click="clearFilter"
          ><feather-icon icon="TrashIcon" size="16" /> Limpiar
        </b-button>
      </b-col>
      <b-col>
        <div class="float-right">
          <b-button variant="success" class="mr-1" @click="excelData">
            <b-icon icon="cloud-download" aria-hidden="true"/><span v-if="windowWidth > 767"> Excel</span>
          </b-button>
          <b-button variant="success" @click="getOrdersByFilters">
            <b-icon icon="search"/><span v-if="windowWidth > 767"> Buscar</span> 
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import * as moment from "moment";
import { mapActions, mapState, mapGetters } from "vuex";

import { currentDate, typeFilter } from "@/helpers/helpers";

export default {
  async mounted() {
    if (!this.idUserSelect) {
      this.idUserSelect = this.user.idUser;
    }
    await this.getInitialContentCheckout();
  },
  components: {},
  props: {
    tipoFechaSelect: {
      type: Number,
      required: true,
    },
    tipoDataClienteSelect: {
      type: String,
      required: true,
    },

    filtroDataCliente: {
      type: Array,
      required: true,
    },
    filter: {
      type: String,
      required: false,
    },
    dateYYMM: {
      type: String,
      required: false,
    },
    minYearMax: {
      type: Object,
      required: true,
    },
    dateCurrentInit: {
      type: String,
      required: false,
    },
    dateCurrentEnd: {
      type: String,
      required: false,
    },
    perPage: {
      type: Number,
      required: false,
    },
    pageOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      idUserSelect: null,
      tipoFecha: this.tipoFechaSelect,
      typegroup: 0,
      busquedaSelect: "",
      hotel: 0,
      fechaInicio: null,
      fechaFin: null,
      porPagina: this.perPage,
      fechaActual: currentDate(),
      fechaExcel: currentDate(),
      filtroText: this.filter,
      dateByMonth: this.dateYYMM,
    };
  },
  computed: {
    ...mapGetters("groups", ["filteredOrders"]),
    ...mapState("auth", ["user"]),
    ...mapState("start", ["hotels"]),
    ...mapState("groups", ["typeGroups"]),
    ...mapState("mySales", ["usersChannel"]),
    ...mapState('appConfig',['windowWidth']),
  },
  methods: {
    ...mapActions("groups", ["getInitialContentCheckout"]),
    async getOrdersByMonth() {
      const fechaInicio = moment(this.dateByMonth).format("YYYY-MM-01");
      const fechaFin = moment(this.dateByMonth)
        .endOf("month")
        .format("YYYY-MM-DD");
      const payload = {
        tipoFecha: this.tipoFecha,
        inicio: fechaInicio,
        fin: fechaFin,
        hotel: this.hotel,
        idUser: this.idUserSelect,
        canalVenta: this.user.canalventa.id,
        totalRows: this.filteredOrders.length,
        dateByMonth: this.dateByMonth,
        ...typeFilter(this.tipoBusqueda, this.busquedaSelect),
      };
      // console.log(payload)
      this.$emit("get-Orders-by-Date-Range-filter", payload);
    },
    getOrdersByFilters() {
      const fechaInicio = document.getElementById("dateStart").value;
      const fechaFin = document.getElementById("dateEnd").value;

      const payload = {
        typegroup: this.typegroup,
        fini: fechaInicio,
        ffin: fechaFin,
        hotel: this.hotel,
        idUser: this.idUserSelect,
        canalVenta: this.user.canalventa.id,
        totalRows: this.filteredOrders.length,
        dateByMonth: this.dateByMonth,
        ...typeFilter(this.tipoBusqueda, this.busquedaSelect),
      };
      // console.log(payload)
      this.$emit("get-Orders-by-Date-Range-filter", payload);
    },
    setUserChannel(idUsr) {
      const listUsers = JSON.parse(JSON.stringify(this.usersChannel));
      const usr = listUsers.find((usr) => usr.id === idUsr);
      if (usr) {
        this.idUserSelect = idUsr;
      }
      if (typeof usr === "undefined") {
        this.idUserSelect = idUsr;
      }
      this.getOrdersByFilters();
    },
    filtrarInfo() {
      this.$emit("filtrar-resultado-tabla", this.filtroText);
    },
    clearFiltroText() {
      this.filtroText = "";
      this.$emit("clear-filtrar-resultado-tabla");
    },
    changePerPage() {
      this.$emit("change-per-page", this.porPagina);
    },
    clearFilter() {
      this.fechaInicio = null;
      this.fechaFin = null;
      this.category = 0;
      this.hotel = "0";
      this.typegroup = "0";
      this.filter = null; //el buscador
      this.$emit("clear-filter");
    },
    excelData() {
      const payload = {
        typegroup: this.typegroup,
        fini: this.fechaInicio,
        ffin: this.fechaFin,
        hotel: this.hotel,
        idUser: this.idUserSelect,
        canalVenta: this.user.canalventa.id,
        totalRows: this.filteredOrders.length,
        dateByMonth: this.dateByMonth,
        ...typeFilter(this.tipoBusqueda, this.busquedaSelect),
      };

      this.$emit("excel-data", payload);
    },
  },
};
</script>