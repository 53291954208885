<template>
  <b-modal
    size="sm"
    :id="'modal-delete' + room.id"
    :title="room.roomtypename"
    ok-only
    hide-footer
  >
    <b-card-text>
      <b-row class="mb-2">
        <b-col md="12" class="text-center">
        <validation-observer tag="form" v-slot="{ invalid }">
          <b-col md="12">
            <b-form-group label="Motivos de cancelación">
              <b-form-select v-model="cancelRoom">
                <option value="null">Seleccione</option>
                <option
                  v-for="(cancel, index) in cancelMotive"
                  :key="index"
                  :value="cancel.id"
                >
                  {{ cancel.name }}
                </option>
              </b-form-select>
            </b-form-group>
          </b-col>
            <b-col md="12">
              <validation-provider
                name="notas de cancelación"
                rules="required|max:50"
              >
                <b-form-group
                  label="Nota de cancelacion"
                  slot-scope="{ valid, errors }"
                >
                  <b-form-textarea
                    v-model="room.note"
                    placeholder="Escribe la nota de cancelacion"
                    :maxlength="50"
                    rows="2"
                    :state="errors[0] ? false : valid ? true : null"
                  />
                  <b-form-invalid-feedback v-for="error in errors" :key="error">
                    {{ error }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
        
          <b-col md="12">
            <div class="text-right">
              <b-button
                :disabled="invalid"
                variant="primary"
                @click="setCancelRoom(room)"
              >
                <b-spinner small v-if="isSaving" />Confirmar
              </b-button>
            </div>
          </b-col>
        </validation-observer>
      </b-col>
      </b-row>
    </b-card-text>
  </b-modal>
</template>

<script>
import * as moment from "moment";
import { mapActions, mapMutations, mapState } from "vuex";
import { currentDate } from "@/helpers/helpers";
import {
  adultOptions,
  childrenOptions,
  ageChildrenOptions,
  searchBookingData,
  namesPrefix,
} from "@/data/data";
export default {
  async mounted() {},
  props: {
    room: {
      type: Object,
      required: true,
    },
    num: {
      type: Number,
      required: true,
    },
    cancelMotive: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
      fechaActual: currentDate(),
      adultOptions,
      childrenOptions,
      ageChildrenOptions,
      searchBookingData,
      namesPrefix,
      amount: 0,
      invalid: false,
      cancelRoom: null,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    ...mapActions("groups", ["fetchCancelRoom", "fetchRoomBooking"]),

    ...mapMutations("groups", [
      "addArrayNinios",
      "setAgeChild",
      "removeAgeChild",
      "setTotalNights",
    ]),
    ageChild(object) {
      return object.edad;
    },
    async getLinkPay(orderdetail) {
      const payload = {
        amount: orderdetail.totalpay,
        order: orderdetail.order_id,
        detail: orderdetail.orderdetail_id,
      };

      this.loadvalid = true;
      await this.fetchTransactionPay(payload);
      this.loadvalid = false;
      this.urlbank = this.transactionPay.link;
    },
    createArrayNinios(numberOfChildren) {
      let ageChildrens = [];
      for (let i = 0; i < numberOfChildren.children; i++) {
        ageChildrens.push({ edad: 1 });
      }
      const payload = { ageChildrens, info: numberOfChildren };
      this.addArrayNinios(payload);
    },
    setAgeNinio(indice, edad) {
      const payload = { indice, edad };
      this.setAgeChild(payload);
    },
    deleteAgeChild(indice, room) {
      const payload = { indice, info: room };

      this.removeAgeChild(payload);
    },
    getNights(room) {
      const inicio = moment(room.datein);
      const fin = moment(room.dateout);
      this.room.nights = fin.diff(inicio, "days");
      this.room.total = room.rateInit * room.nights;
    },

    async setCancelRoom(room) {
      // console.log( payload)
      this.isSaving = true;
      const payload = {
        room: room,
        groupID: room.group,
        idDetail: room.orderdetail.orderdetail_id,
        note: room.note,
        cancelCatalog: this.cancelRoom,
        user: this.user.idUser,
      };
      await this.fetchCancelRoom(payload);

      this.isSaving = false;

      this.$root.$emit("bv::hide::modal", "modal-delete" + room.id);

      const payloadUpdate = {
        idGroup: room.group,
        idRoom: room.room,
        datein: room.datein,
        idRoomOnly: "",
        dateout: room.dateout,
      };

      this.$emit("refresh");

      await this.fetchRoomBooking(payload);
    },
  },
};
</script>


